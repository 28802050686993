import {post} from '@/http'

// 收藏
export function getCollectList(data) {
    return new Promise((resolve, reject) => {
        post('/Collect/GetCollectList', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 删除收藏
export function deleteCollect(data) {
    return new Promise((resolve, reject) => {
        post('/Collect/DeleteCollect', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
